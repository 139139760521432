<template>
  <div>
    <!--begin::Dashboard-->
    <div v-if="item && item.id" class="row">
      <div class="col-12">
        <b-card class="card card-custom card-stretch gutter-b" no-body>
            <b-tabs pills card>
                <!-- Add your b-tab components here -->
                <template #tabs-end>
                    <!-- <li role="presentation" class="ml-auto nav-item align-self-center">
                        <b-form-group class="mb-0" description="Statut de la vente">
                            <v-select
                                :value="item.status"
                                @input="onStatusChange"
                                :reduce="status => status.value"
                                :options="statusOptions"
                                :clearable="false"
                                required
                            />
                        </b-form-group>
                    </li> -->
                    <li role="presentation" class="ml-auto nav-item align-self-center">
                        <template v-if="isQuotation">
                            <b-badge v-if="item.status === 'DONE'" variant="success">
                                DEVIS TERMINÉ
                            </b-badge>
                            <b-button v-else-if="item.status === 'ACCEPTED'" @click.prevent="doneQuotation" type="button" variant="light-success" small>
                                <i class="fas fa-check mr-3" /> Devis terminé
                            </b-button>
                            <b-button v-else @click.prevent="acceptQuotation" type="button" variant="success">
                                <i class="fas fa-sm fa-check mr-4" /> Figer ce devis
                            </b-button>
                        </template>
                        <template v-else>
                            <!-- <b-badge v-if="item.status === 'PENDING'" variant="warning">VENTE EN COURS</b-badge> -->
                            <b-badge v-if="item.status === 'DONE'" variant="success">
                                VENTE FINALISÉE
                            </b-badge>
                            <b-badge v-else-if="item.status === 'CANCELED'" variant="danger">
                                VENTE ANNULÉE
                            </b-badge>
                            <b-button v-else @click.prevent="finalizeSale" type="button" variant="warning" small>
                                <i class="fas fa-check mr-3" /> Finaliser la vente
                            </b-button>
                        </template>
                    </li>
                </template>

                <b-tab active>
                    <template v-slot:title>
                        <i class="fas fa-info-circle mr-3" />
                        {{ isQuotation ? 'Devis' : 'Vente' }}
                    </template>
                    <b-row v-if="item">
                        <b-col lg="12" xl="9">
                            <h4><i class="fas fa-boxes mr-3 mb-4" /> Ajouter des éléments</h4>
                            <!--begin::Table wrapper-->
                            <div class="table-responsive mb-10">
                                <!--begin::Table-->
                                <table class="table g-5 gs-0 mb-0 fw-bolder text-gray-700">
                                    <!--begin::Table head-->
                                    <thead>
                                        <tr class="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                            <th class="min-w-300px w-475px">Désignation</th>
                                            <th class="min-w-110px w-110px">P.U. HT</th>
                                            <th class="min-w-80px w-80px">Qté</th>
                                            <th class="min-w-100px w-80px">TVA</th>
                                            <th class="min-w-100px w-110px">Total HT</th>
                                            <th class="min-w-80px w-80px text-end"></th>
                                        </tr>
                                    </thead>
                                    <!--end::Table head-->
                                    <!--begin::Table body-->
                                    <tbody>
                                        <tr v-for="productItem in item.products" :key="`product-item-${productItem.id}`" class="border-bottom border-bottom-dashed">
                                            <td class="pe-7">
                                                {{ productItem.product.name }}
                                            </td>
                                            <td>
                                                {{ productItem.newPrice }}€
                                            </td>
                                            <td class="ps-0">
                                                {{ productItem.quantity }}
                                            </td>
                                            <td>
                                                {{ productItem.vat }}%
                                            </td>
                                            <td class="text-end text-nowrap">
                                                {{ productItem.newPrice * productItem.quantity }}€
                                            </td>
                                            <td v-if="canEditSale" class="pt-6 text-end">
                                                <a href="#" @click.prevent="editProductItem(productItem)" class="mr-5">
                                                    <i class="fas fa-pencil-alt text-primary"/>
                                                </a>
                                                <a href="#" @click.prevent="deleteProductItem(productItem)">
                                                    <i class="fas fa-trash-alt text-danger"/>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr v-if="canEditSale">
                                            <th colspan="6" class="text-muted py-2 text-right">
                                                <b-button @click.prevent="addProductItem" class="py-1" variant="outline-primary" title="Ajouter un élément à la vente">
                                                    <i class="fas fa-sm fa-plus mr-4" /> Ajouter
                                                </b-button>
                                            </th>
                                        </tr>
                                    </tbody>
                                    <!--end::Table body-->
                                </table>
                            </div>
                            <!--end::Table-->
                            <div class="separator separator-dashed mb-8"></div>

                            <!--begin::Discount-->
                            <h4><i class="fas fa-tag mr-3 mb-4" /> Remise</h4>
                            <b-form-row>
                                <b-col sm="12" lg="6">
                                    <b-form-group
                                        label-cols-sm="3"
                                        label-cols-lg="5"
                                        label="Remise en % (sur prix HT)"
                                    >
                                        <b-form-input
                                            type="number"
                                            step="0.01"
                                            v-model="item.discount"
                                            @input="debounceDiscountChange('discount')"
                                            :disabled="!canEditSale"
                                            class="form-control form-control-solid"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <!--end::Discount-->

                            <!--begin::Discount-->
                            <div v-if="false" class="mb-0">
                                <b-form-checkbox @change="toggleDiscount" :checked="item.discount !== null" :unchecked-value="null" :disabled="!canEditSale" switch>
                                    <p class="form-label fs-6 fw-bolder text-gray-700">Appliquer une remise</p>
                                </b-form-checkbox>
                                <!--begin::Input group-->
                                <div v-if="item.discount !== null" class="mb-2">
                                    <b-form-row>
                                        <b-col sm="12" lg="6">
                                            <b-form-group
                                                label-cols-sm="3"
                                                label-cols-lg="5"
                                                label="Remise en % (sur prix HT)"
                                            >
                                                <b-form-input
                                                    type="number"
                                                    step="0.01"
                                                    v-model="discount.discountPercentage"
                                                    @input="debounceDiscountChange('discountPercentage')"
                                                    :disabled="!canEditSale"
                                                    class="form-control form-control-solid"
                                                />
                                            </b-form-group>
                                        </b-col>

                                        <b-col sm="12" lg="6">
                                            <b-form-group
                                                label-cols-sm="3"
                                                label-cols-lg="5"
                                                label="Prix HT avec Remise"
                                            >
                                                <b-form-input
                                                    type="number"
                                                    step="0.01"
                                                    v-model="discount.priceWithoutVat"
                                                    @input="debounceDiscountChange('priceWithoutVat')"
                                                    :disabled="!canEditSale"
                                                    class="form-control form-control-solid"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="12" lg="6">
                                            <b-form-group
                                                label-cols-sm="3"
                                                label-cols-lg="5"
                                                label="Prix TTC avec Remise"
                                            >
                                                <b-form-input
                                                    type="number"
                                                    step="0.01"
                                                    v-model="discount.priceWithVat"
                                                    @input="debounceDiscountChange('priceWithVat')"
                                                    :disabled="!canEditSale"
                                                    class="form-control form-control-solid"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                </div>
                            </div>
                            <!--end::Discount-->
                            <div class="separator separator-dashed mb-8"></div>

                            <div class="text-right">
                                <h5>Total HT : {{ item.totalWithoutVat.toFixed(2) }}€</h5>
                                <h5 v-if="item.discount">Remise ({{ parseFloat(item.discount).toFixed(2) }}%) : -{{ (item.totalWithoutVat * (parseFloat(item.discount) / 100)).toFixed(2) }}€</h5>
                                <h5 v-for="(vatRate, idx) of item.vatRates" :key="`vat-rate-${idx}`">
                                    TVA {{ vatRate.rate.toFixed(2) }}% <small>de {{ vatRate.baseAmount.toFixed(2) }}€</small>  : {{ vatRate.vatAmount.toFixed(2) }}€
                                </h5>
                                <h5>Total TTC : {{ item.totalWithVat.toFixed(2) }}€</h5>
                            </div>
                        </b-col>

                        <b-col lg="12" xl="3">

                            <div v-if="isQuotation && item.treatment" class="mb-8">
                                <h4>
                                    <i class="fas fa-clipboard mr-3" /> Prise en charge
                                </h4>
                                <router-link :to="{ name: 'treatment', params: { id: item.treatment.id } }">
                                    Voir la PEC associée N°{{item.treatment.number}}
                                </router-link>
                            </div>

                            <div class="mb-8">
                                <h4><i class="fas fa-user mr-3" /> Informations Client</h4>
                                <div v-if="item.customer">
                                    <router-link :to="`/customers/${item.customer.id}`">
                                        {{ item.customer.firstname }} {{ item.customer.lastname.toUpperCase() }}
                                    </router-link>
                                    <br>
                                    <a :href="`mailto:${item.customer.email}`" target="_blank">{{ item.customer.email }}</a><br>
                                    <a :href="`tel:${item.customer.phone}`">{{ item.customer.phone }}</a><br>
                                    {{ item.customer.address }}
                                </div>
                                <div v-else>
                                    Client supprimé
                                </div>
                            </div>
                            
                            <div v-if="item.invoices && item.invoices.length">
                                <h4>
                                    <i class="fas fa-file-invoice mr-3" /> Facture{{ item.invoices.length > 1 ? 's' : '' }}
                                </h4>
                                <ul>
                                    <li v-for="invoice in item.invoices" :key="invoice.id">
                                        <router-link :to="{ name: 'invoice', params: { id: invoice.id } }">
                                            Facture N°{{ invoice.numberFormatted }} {{ invoice.isRefund ? '(Avoir)' : '' }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                            
                            <div v-if="isQuotation" class="mb-8">
                                <!--begin::Row-->
                                <div class="row">
                                    <!--begin::Col-->
                                    <div class="col">
                                        <b-button v-if="pdfLoading" variant="outline-primary" class="w-100" disabled>
                                            <b-spinner small type="grow" /> PDF
                                        </b-button>
                                        <b-button v-else @click.prevent="exportPdf" type="button" variant="outline-primary" class="w-100">
                                            <i class="fas fa-file-pdf mr-3" /> PDF
                                        </b-button>
                                    </div>
                                    <!--end::Col-->
                                    <!--begin::Col-->
                                    <div class="col">
                                        <b-button v-if="emailLoading" variant="outline-primary" class="w-100" disabled>
                                            <b-spinner small type="grow" /> Email
                                        </b-button>
                                        <b-button v-else @click.prevent="sendEmail" type="button" variant="outline-primary" class="w-100">
                                            <i class="fas fa-paper-plane mr-3" /> Email
                                        </b-button>
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>

                            <div v-if="item.status === 'DONE'">
                                <b-button @click.prevent="cancelSale" type="button" variant="danger" size="sm" class="d-block mx-auto">
                                    <i class="fas fa-times mr-3" /> Annuler la vente
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab :disabled="item.status !== 'DONE'">
                    <template v-slot:title>
                        <i class="fas fa-box-open mr-3" />
                        Décaissement
                    </template>
                    <b-row>
                        <b-col>
                            <template v-if="item.destocked">
                                <p>Le décaissement a été effectué le {{ new Date(item.destocked).toLocaleDateString() }}.</p>
                                <b-button variant="outline-danger" @click.prevent="restockSale">
                                    <i class="fas fa-box mr-3" /> Annuler le décaissement
                                </b-button>
                            </template>
                            <template v-else>
                                <p>Le décaissement n'a pas encore été effectué.</p>
                                <b-button variant="primary" @click.prevent="destockSale">
                                    <i class="fas fa-box-open mr-3" /> Décaisser
                                </b-button>
                            </template>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal v-model="modal.show" :title="modal.productItem.id ? 'Modifier la ligne' : 'Ajouter une ligne au devis'" hide-footer no-close-on-backdrop>
     <div class="d-block">
        <b-form id="modal-form" @submit.prevent="onSubmitProductItem">
            <b-form-group label="Produit ou service :">
              <v-select
                v-model="modal.productItem.product"
                @search="searchProductOptions"
                @input="onProductOptionSelected"
                :options="productOptions"
                :filterable="false"
                label="id"
                placeholder="Rechercher un produit/service (nom ou référence)..."
                required
              >
                <template slot="no-options">Aucun produit/service trouvé</template>
                <template slot="option" slot-scope="option">
                    {{ option.name }} ({{ option.reference }})
                </template>
                <template slot="selected-option" slot-scope="option">
                    {{ option.name }} ({{ option.reference }})
                </template>
              </v-select>
            </b-form-group>

            <b-form-group label="Quantité :">
              <b-form-input
                v-model="modal.productItem.quantity"
                type="number"
                min="0"
                placeholder="Quantité"
                required
              />
            </b-form-group>

            <b-form-group
                label="Prix HT :"
                :description="modal.productItem.product
                    ? `Prix d'origine du produit sélectionné : ${modal.productItem.product.price}€`
                    : ''
                "
            >
              <b-form-input
                v-model="modal.productItem.newPrice"
                type="number"
                min="0"
                step="0.01"
                placeholder="Prix"
              />
            </b-form-group>

            <b-form-group
                label="Taux de TVA (%) :"
                :description="modal.productItem.product && modal.productItem.product.family
                    ? `TVA d'origine du produit sélectionné : ${modal.productItem.product.family.vat}%`
                    : ''
                "
            >
              <b-form-input
                v-model="modal.productItem.vat"
                type="number"
                min="0"
                step="0.01"
                placeholder="Taux de TVA"
              />
            </b-form-group>
            
            <div class="text-right">
                <b-button class="mr-2" @click.prevent="hideProductItemModal" variant="danger">Annuler</b-button>
                <b-button type="submit" variant="success">
                    {{ modal.productItem.id ? 'Modifier le produit' : 'Ajouter le produit' }}
                </b-button>
            </div>
        </b-form>
     </div>
   </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import _ from "lodash";

export default {
  name: "sale",
  data() {
    return {
      isQuotation: false,
      item: {
            id: null,
            destocked: null,
            isCanceled: null,
            billDate: null,
            showTotalWithVat: null,
            discount: null,
            doneAt: null,
            status: null,
            customer: null,
            products: [],
      },
      modal: {
          show: false,
          productItem: {
              product: null,
              quantity: 1,
              newPrice: null,
          },
      },
      productOptions: [],
      statusOptions: [
          { value: 'PENDING', label: 'En cours' },
          { value: 'DONE', label: 'Terminé' },
      ],
      discount: {
          priceWithoutVat: null,
          priceWithVat: null,
          discountPercentage: null,
      },
      total: {
          withoutVat: null,
          withVat: null,
          vatOnly: null,
      },
      pdfLoading: false,
      emailLoading: false,
    };
  },
  computed: {
    canEditSale() {
        return ["DRAFT",    "PENDING"].includes(this.item.status) && !this.item.destocked;
    },
  },
  mounted() {
    this.isQuotation = this.$route.name === 'quotation';
    
    if (this.isQuotation) {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Devis", route: { name: "quotations" } }, { title: "Devis" }]);
    } else {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ventes", route: { name: "sales" } }, { title: "Vente" }]);
    }

    this.$router.onReady(() => {
        this.fetchItem(this.$route.params.id);
    });
  },
  methods: {
    onProductOptionSelected(product) {
        // Auto set VAT 
        this.modal.productItem.vat = product && product.family
            ? product.family.vat
            : 20;

        // Auto set Price 
        this.modal.productItem.newPrice = product && product.price
            ? product.price
            : null;
    },
    /**
     * Toggle invoice discount
     */
    toggleDiscount() {
        if (this.item.discount === null) {
            this.item.discount = 10.00;
            this.computeDiscount('discount');
        } else {
            this.item.discount = null;
        }
    },
    /**
     * Debounce input when the discount is changed by the user
     */
    debounceDiscountChange: _.debounce(function() {
        this.saveItem();
    }, 500),
    /**
     * Close product item modal
     */
    hideProductItemModal() {
        this.modal.show = false;
    },
    /**
     * Trigerred when the user searches for a product/service
     */
    searchProductOptions(search, loading) {
      if (!search.length) return;
      this.fetchProductOptions(search, loading, this);
    },
    /**
     * Fetch products & services options (debounced)
     */
    fetchProductOptions: _.debounce((search, loading, vm) => {
      loading(true);

      Promise.all([
            ApiService.query('/products', { params: { name_contains: search, reference_contains: search, _or: 1, populate: 'family' } }),
            ApiService.query('/services', { params: { name_contains: search, reference_contains: search, _or: 1 } }),
      ]).then((res) => {
          vm.productOptions = [
              ...res[0].data.results.map(e => ({ ...e, kind: "Product" })),
              ...res[1].data.results.map(e => ({ ...e, kind: "Service" })),
          ];
          loading(false);
      })
      .catch(() => { loading(false); });
    }, 350),
    /**
     * Add a new product item to the sale
     */
    addProductItem() {
        this.productOptions = [];
        this.modal.productItem = { product: null, quantity: 1, newPrice: null };
        this.modal.show = true;
    },
    /**
     * Edit a product item attached to the sale
     */
    editProductItem(productItem) {
        this.modal.productItem = productItem;
        this.productOptions = [productItem.product];
        this.modal.show = true;
    },
    /**
     * Delete a product item from the sale
     */
    deleteProductItem(productItem) {
        ApiService
            .delete(`/product-items/${productItem.id}`)
            .then(() => {
                this.fetchItem(this.item.id);
            })
            .catch(err => {
                console.error(err);
            });
    },
    /**
     * Save product item from the modal
     */
    onSubmitProductItem() {
        const productItem = {
            quantity: this.modal.productItem.quantity,
            newPrice: this.modal.productItem.newPrice,
            vat: this.modal.productItem.vat,
            kind: this.modal.productItem.product.kind, // Product / Service
            product: this.modal.productItem.product.id,
            attachedToKind: "Sale",
            attachedToDocument: this.item.id, // Current Sale
        };

        if (this.modal.productItem.id) {
            ApiService
                .update('/product-items', this.modal.productItem.id, productItem)
                .then(() => {
                    this.fetchItem(this.item.id); // Refresh Sale
                    this.hideProductItemModal();
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            ApiService
                .post('/product-items', productItem)
                .then(() => {
                    this.fetchItem(this.item.id); // Refresh Sale
                    this.hideProductItemModal();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    },
    /**
     * Fetch sale
     */
    fetchItem(id) {
        ApiService
            .get('/sales', id)
            .then(({ data }) => {
                this.item = data;
            })
            .catch(() => {
                alert('Vente introuvable');
            });
    },
    /**
     * Save sale parameters
     */
    saveItem() {
        // Delete virtual fields
        const sale = _.cloneDeep(this.item);
        delete sale.products;
        sale.customer = sale.customer.id;
        if (sale.treatment) {
            sale.treatment = sale.treatment.id;
        }

        ApiService
            .update('/sales', this.item.id, sale)
            .then(() => {
                this.fetchItem(this.item.id);
                this.$swal('Vente mise à jour', '', 'success');
            });
    },
    /**
     * Update sale status
     */
    // onStatusChange(value) {
    //     this.item.status = value;
    //     this.saveItem();
    // },
    /**
     * Delete sale (deprecated)
     */
    deleteItem() {
        this.$swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Souhaitez-vous supprimer cette vente ? Cette action est irréversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                ApiService
                    .delete(`/sales/${this.item.id}`)
                    .then(() => {
                        this.$swal.fire('Supprimé !', 'La vente a bien été supprimée', 'success');
                        this.$router.push({ name: 'customers' });
                    });
            }
        });
    },
    /**
     * Accept quotation
     */
    acceptQuotation() {
        if (!this.item.customer) {
            this.$swal('Action impossible : aucun client rattaché', '', 'error');
            return;
        }

        this.$swal.fire({
            title: 'Accepter ce devis ?',
            text: "Suite à cette opération, le devis et la PEC associé ne pourront plus être modifiés.",
            //  et une facture sera générée.
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDA702',
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                this.item.status = 'ACCEPTED';
                this.saveItem();
            }
        });
    },
    /**
     * Set a quotation as "Done"
     */
    doneQuotation() {
        if (!this.item.customer) {
            this.$swal('Action impossible : aucun client rattaché', '', 'error');
            return;
        }

        this.$swal.fire({
            title: 'Terminer ce devis ?',
            text: "Suite à cette opération, une facture sera générée pour ce devis et la PEC associé sera marquée comme terminée.",
            //  et une facture sera générée.
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDA702',
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                this.item.doneAt = Date.now();
                this.item.status = 'DONE';
                this.saveItem();
            }
        });
    },
    /**
     * Finalize Sale
     */
    finalizeSale() {
        if (!this.item.customer) {
            this.$swal('Action impossible : aucun client rattaché', '', 'error');
            return;
        }

        this.$swal.fire({
            title: 'Finaliser la vente ?',
            text: "Suite à cette opération, la vente ne pourra plus être modifiée et une facture sera générée.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDA702',
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                this.item.doneAt = Date.now();
                this.item.status = 'DONE';
                this.saveItem();
            }
        });
    },
    cancelSale() {
        if (!this.item.customer) {
            this.$swal('Action impossible : aucun client rattaché', '', 'error');
            return;
        }

        this.$swal.fire({
            title: 'Annuler la vente ?',
            text: "Suite à cette opération, un avoir sur la facture de la vente sera automatiquement généré.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDA702',
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                this.item.canceledAt = Date.now();
                this.item.status = 'CANCELED';
                this.saveItem();
            }
        });
    },
    destockSale() {
        this.$swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Souhaitez-vous vraiment décaisser cette commande ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                ApiService
                    .post(`/sales/${this.item.id}/destock`)
                    .then(() => {
                        this.fetchItem(this.item.id);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        });
    },
    restockSale() {
        this.$swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Souhaitez-vous vraiment annuler le décaissement de cette commande ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                ApiService
                    .post(`/sales/${this.item.id}/restock`)
                    .then(() => {
                        this.fetchItem(this.item.id);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        });
    },
    /**
     * Export quotation PDF
     */
    exportPdf() {
        this.pdfLoading = true;
        ApiService
            .query(`/sales/${this.item.id}/pdf`, { responseType: 'arraybuffer' })
            .then(({ data }) => {
                const blob = new Blob([data], { type: 'application/pdf' } );
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `devis-${this.item.number}.pdf`);
                document.body.appendChild(link);
                link.click();
                this.pdfLoading = false;
            })
            .catch((err) => {
                console.error(err);
                this.pdfLoading = false;
            });
    },
    /**
     * Send quotation by email
     */
    sendEmail() {
        this.emailLoading = true;
        ApiService
            .post(`/sales/${this.item.id}/email`)
            .then(() => {
                this.$swal(`Devis envoyé à ${this.item.customer.email}`, '', 'success');
                this.emailLoading = false;
            })
            .catch((err) => {
                console.error(err);
                this.emailLoading = false;
            });
    },
  },
};
</script>